import { Storage } from "aws-amplify";

const BASE_URL = 'https://adastec.api.flowride.ai/';
const apiKey = "ujAeQriZUL1w7RUdlS4NT7QDLqDbYOIB92wp8FOE";

async function getActiveRoute(root) {
  try {
    const url = await Storage.get(root + "/active_route.txt", {
      customPrefix: {
        public: "",
      },
    });
    const result = await fetch(url);
    return result.text().then((route_type) => {
      return route_type;
    });
  } catch (e) {
    throw new Error(`Could not retrieve file from S3: ${e.message}`);
  }
}

async function getRoute(root) {
  try {
    const routeType = await getActiveRoute(root);
    const url = await Storage.get(root + "/" + routeType + ".json", {
      customPrefix: {
        public: "",
      },
    });
    const result = await fetch(url);
    return result.json().then((route) => {
      return route;
    });
  } catch (e) {
    throw new Error(`Could not retrieve file from S3: ${e.message}`);
  }
}

function convertTime(date) {
  date = new Date(date * 1000);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const getKeyEvents = async (vehicleId, startDate, endDate, eventId) => {
  const start_date = convertTime(startDate);
  const end_date = convertTime(endDate);

  let response = await fetch(
    BASE_URL +
      "keyevents/" +
      vehicleId +
      '?event_id=' +
      eventId +
      '&start_date=' +
      start_date +
      "&end_date=" +
      end_date,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + apiKey,
      },
    }
  );
  let result = await response.json();
  result = result.map((obj) => ({
    timestamp: new Date(obj.timestamp).getTime() / 1000,
    lat: parseFloat(obj.gps_lat),
    lng: parseFloat(obj.gps_long),
    vehicle: vehicleId,
  }));
  return result;
};
export default getRoute;
